.container{
     padding: 128px 0;
     background: #FFF;
     position: relative;
 }


 .content{
     padding: 32px;
     display: grid;
     margin: 0 auto;
     min-height: 50vh;
     overflow: hidden;
     background: #000;
     border-radius: 20px;
     width: min(90vw,1280px);
     grid-template-columns: 1fr 1fr;
     @media (max-width: 1000px) {
          gap: 0;
          grid-template-columns: 1fr;
  }
 }
 
 
 .canvas{
     @media (max-width: 886px) {
          margin:0 auto;
     }
}

.cool{
     gap:12px;
     display: flex;
     padding: 0 32px;
     align-self: center;
     flex-direction: column;
     @media (max-width: 886px) {
          justify-self: center;
      }
}
.contact{
     cursor: pointer;
}

.p{
     margin: 0;
}