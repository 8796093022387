
:root{
  --animation-delay : 0
}

*[data-anim-hide="true"]{
  opacity: 1;
  --animation-delay:0;
  animation-duration:1500ms;
  animation-name: anim-fade;
  animation-fill-mode: backwards !important;
  animation-delay: calc(var(--animation-delay) * 100ms);
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
*[data-anim-hide="false"]{
  opacity: 0;
  --animation-delay:0;
  animation-duration:1500ms;
  animation-name: anim-hide;
  animation-fill-mode: backwards !important;
  animation-delay: calc(var(--animation-delay) * 100ms);
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

*[data-anim-fade="true"],
*[data-anim-scale="true"],
*[data-anim-page-up="true"],
*[data-anim-page-right="true"],
*[data-anim-translate-up="true"],
*[data-anim-translate-left="true"],
*[data-anim-translate-right="true"]{
  opacity: 1;
  animation-duration:1500ms;
  animation-fill-mode: backwards !important;
  animation-delay: calc(var(--animation-delay) * 100ms);
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

*[data-anim-fade="true"]{
  animation-name: anim-fade;
}
*[data-anim-page-up="true"]{
  animation-name: anim-translate-up;
}
*[data-anim-page-right="true"]{
  animation-name: anim-translate-right;
}
*[data-anim-translate-up="true"]{
  animation-name: anim-translate-up;
}
*[data-anim-translate-left="true"]{
  animation-name: anim-translate-left;
}
*[data-anim-translate-right="true"]{
  animation-name: anim-translate-right;
}
*[data-anim-scale="true"]{
  animation-name: anim-scale;
}

@keyframes anim-hide {
  0%{
      opacity: 1;
  }
  100%{
      opacity: 0;
  }
}

@keyframes anim-fade {
  0%{
      opacity: 0;
  }
  100%{
      opacity: 1;
  }
}

@keyframes anim-page-right {
  0%{
      transform: translateX(100vh);
  }
  100%{
      transform: translateX(0);
  }
}

@keyframes anim-translate-up {
  0%{
      opacity: 0;
      transform: translateY(5vh);
  }
  100%{
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes anim-page-right {
  0%{
      transform: translateX(100vh);
  }
  100%{
      transform: translateX(0);
  }
}

@keyframes anim-page-up {
  0%{
      opacity: 0;
      transform: translateY(50vh) scale(0.8);
  }
  100%{
      opacity: 1;
      transform: translateY(0) scale(1);
  }
}

@keyframes anim-translate-left {
  0%{
      opacity: 0;
      transform: translateX(-5vh);
  }
  100%{
      opacity: 1;
      transform: translateY(0);
  }
}
@keyframes anim-translate-right {
  0%{
      opacity: 0;
      transform: translateX(5vh);
  }
  100%{
      opacity: 1;
      transform: translateY(0);
  }
}


@keyframes anim-scale {
  0%{
      opacity: 0;
      transform: scale(0.25);
  }
  100%{
      opacity: 1;
      transform: scale(1);
  }
}