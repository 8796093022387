
.container{
  z-index: 4;
    height: 100vh;
    position: relative;
}

.bg{
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: -9999;
  position: fixed;
  object-fit: cover;
}
.bg_shadow {
  inset: 0;
  height: 100vh;
  z-index: -9999;
  position: fixed;
  background: linear-gradient(130deg, rgba(0, 0, 0, 1) 40%, rgba(255, 255, 255, 0.5) 100%); /* Adjust the gradient colors */
}

.video{
    gap: 16px;
    display: grid;
    position: relative;
    padding-top: 15dvh;
    grid-template-columns: auto auto;
}

.content{
    gap: 42px;
    display: flex;
    margin: 0 auto;
    padding: 0 16px;
    overflow: hidden;
    padding-top: 15dvh;
    flex-direction: column;
    width: min(90vw,1080px);
    align-items: flex-start;

    @media (max-width: 760px) {
      margin: 0;
      width: 100%;
      padding-inline:32px;
    }
  } 

  .text_content{
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    gap: min(2.5cqmin,24px);
  }
.main_content{
    width: 700px;
    display: flex;
    font-weight: 700;
    filter: brightness(2);
    flex-direction: column;
    font-size: min(7cqmin,42px);
    animation: show 500ms ease 300ms backwards;

    @media (max-width: 760px) {
      width: auto;
    }
}



@keyframes show {
    from{
        opacity: 0;
        scale:0.75;
        translate: 0 15%;
    }
    to{
        scale: 1;
        opacity: 1;
        translate: 0 0;
    }
  }

  .info{
    display: flex;
    color: #888;
    width: 550px;
    font-size: 18px;
    overflow: hidden;
    animation: show 500ms ease 350ms backwards;
      @media (max-width: 760px) {
      width: auto;
    }
  }

  .button{
    gap: 12px;
    display: flex;
    color: #000;
    font-weight: 600;
    padding: 18px 32px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    border: 1px solid #FFFFFF55;
    background: var(--green-color);
    animation: show 500ms ease 375ms backwards;
  }

  .exp_content{
    gap: 48px;
    display: flex;
    margin-top: 42px;
    @media (max-width: 560px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  .exp_content_item{
    display: flex;
    flex-direction: column;
    animation: show 500ms ease 400ms backwards;
  }

  .exp_content_item>p{
    margin: 0;
  }