.container{
     padding: 128px 0;
     background: #000;
}
.text{
     line-height: 24px;
     text-align: center;
     margin: 0px auto 96px;
     padding: 0 22px;
    width: min(90vw, 960px);
    background: rgb(0, 0, 0);
    @media (max-width: 1000px) {
          width: 100%;
          margin: 0px 0 96px 0;
     }
}

.content{
     gap: 64px;
     display: flex;
     margin: 0 auto;
     padding: 0 22px;
     width: min(90vw,960px);
     @media (max-width: 1000px) {
          margin: 0;
          width: 100%;
          flex-direction: column;
      }
 }



 .content > div{
     flex:1;
 }

 .menu{
     margin-top: 54px;
     @media (max-width: 1000px) {
          margin-top: 0;
      }
 }
 


 .menu_list{
      gap: 8px;
      display: flex;
      margin-top: 12px;
      color: #FFFFFFAA;
      flex-direction: column;
 }

 .menu_list > span{
     cursor: pointer;
 }


.contact{
     display: flex;
     margin-top: 54px;
     flex-direction: column;
     @media (max-width: 1000px) {
               margin-top: 0;
      }
 }


 .content_header{
     display: flex;
     margin-bottom: 32px;
     flex-direction: column;
 }
 .content_header > h1{
     font-size: min(8vmin,54px);
}
 .content_header > p{
     margin: 0;
 }

.input_container{
     gap:14px;
     display: flex;
     margin-top: 18px;
     flex-direction: column;
}

.div{
     gap: 8px;
     display: flex;
     position: relative;
}
.input{
     flex:1;
     border: none;
     color: #FFF;
     background: #000;
     border-radius: 12px;
     padding: 18px 24px 18px 42px;
     transition: 250ms ease-in-out;
     border: 1px solid rgba(255, 255, 255, 0.35);
}

.input_icon{
     left: 15px;
     top: 50%;
     position: absolute;
     transform: translateY(-50%);
}
.input::placeholder{
     font-size: 14px;
}
.input:focus{
  outline:none;
  border-color: var(--green-color);
}

.input:focus > .input::placeholder{
     font-size: 44px;
}

.button{
     font-size: 12px;
     cursor: pointer;
     font-weight: 700;
     margin-top: 24px;
     overflow: hidden;
     padding: 22px 22px;
     border-radius: 8px;
     position: relative;
     background: transparent;
     color: var(--text-color-soft);
     transition: 500ms ease-in-out;
     border: 1px solid var(--green-color);
}
.button:hover{
     background: var(--green-color);
}
.send_icon{
     top: 50%;
     left: 50%;
     opacity: 0;
     width: 16px;
     height: 16px;
     color: #000;
     position: absolute;
     transition: 500ms ease-in-out;
     transform: translate(-50%,-10%) scale(0.5);
}
.email_icon{
     top: 50%;
     left: 50%;
     color: #FFF;
     position: absolute;
     transition: 500ms ease-in-out;
     transform: translate(-50%,-50%) scale(1);
}
.button:hover .send_icon{
     opacity: 1;
     transform: translate(-50%,-50%) scale(1);
}

.button:hover .email_icon{
     opacity: 0;
     transform: translate(-50%,-10%) scale(0.5);
}