.header{
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    display: flex;
    margin: 0 auto;
    position: sticky;
    padding: 32px 64px;
    flex-direction: column;
    justify-content: center;
    transition: 500ms ease-in-out;
    border-bottom: 1px solid #FFFFFF00;
}
.header[data-scroll="true"]{
    padding: 12px 64px;
    background: #000000AA;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-bottom: 1px solid #FFFFFF99;
}




.ul{
    gap:32px;
    padding: 0;
    display: flex;
    @media (max-width: 1020px) {
            height: 100vh;
            align-items: flex-end;
            flex-direction: column;
            animation: header-anim 500ms ease-in-out;
    };
    @media (max-width: 1020px) {
        gap: 62px;
        padding-top: 128px;
    }
}



  .nav{
    margin: 0 auto;
}

  @media (max-width: 1020px) {
    .nav{
        top: 0;
        left: 0;
        width: 100vw;
        margin: 0 auto;
        height: 100vh;
        position: fixed;
        max-width: 920px;
        padding: 64px 64px;
        background: #000000;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
    }
    .nav[data-active="true"]{
        opacity: 1;
        pointer-events: all;
        animation: header-active-anim 500ms ease-in-out;
    }
    .nav[data-active="false"]{
        opacity: 0;
        pointer-events: none;
        animation: header-hide-anim 500ms ease-in-out;
    }
    @keyframes header-active-anim {
        0%{opacity: 0;}
        100%{opacity: 1;}
    }
    @keyframes header-hide-anim {
        0%{opacity: 1;}
        100%{opacity: 0;}
    }
  }
  


.ul > li {
    cursor: pointer;
    font-size: 12px;
    position: relative;
    list-style-type:none;
    transition: 500ms ease-in-out;
}


.ul > li > a {
    font-size: 16px;
    text-decoration: none;
    transition: 500ms ease-in-out;
    color: var(--text-color-soft);
    @media (max-width: 1020px) {
            font-size: 22px;
        }
}


.ul > li:hover a {
    color: var(--text-color);
}

.ul > li:hover a::before {
    width: 50px;
    background: var(--text-color);
}
.ul > li > a[data-active="true"] .function{
    color: rgb(212, 213, 154);
}
.ul > li > a[data-active="true"] .bracket{
    color:rgb(207, 84, 205)
}


.ul > li > a[data-active="true"]{
    color: var(--text-color);
}
