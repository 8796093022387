
.container{
    padding: 128px 0;
    background: #000;
    position: relative;
}
.content{
    gap: 64px;
    display: flex;
    margin: 0 auto;
    padding: 0 22px;
    width: min(90vw,960px);
    flex-direction: column;
    @media (max-width: 800px) {
        margin: 0;
        width: 100%;
    }
}
.content_header{
    display: flex;
    margin-bottom: 32px;
    flex-direction: column;
}
.content_header>h1{
    font-size: min(8vmin,54px);
}

  .info{
    margin: 0;
  }

.items{
    gap: 64px;
    z-index: 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-direction: column;
    justify-content: center;
}

.item{
    gap: 12px;
    display: flex;
    padding-left: 18px;
    width: min(90vw,650px);
    border-left: 1px solid #87c2ff88;
}

.header{
    gap: 12px;
    display: flex;
    align-items: center;
}
.languages{
    gap:5px;
    display: flex;
    flex-wrap: wrap;
}

.language{
    margin: 0;
    font-size: 13px;
    padding: 4px 12px;
    font-weight: 600;
    border-radius: 12px;
    color: var(--green-color);
    background: rgba(45, 212, 191, 0.13);
}

.p{
    margin: 8px 0;
    line-height: var(--p-line-height);
}