.footer{
    gap:12px;
    z-index: 4;
    display: flex;
    color: #FFF;
    padding: 32px 0;
    background: #000;
    align-items:center;
    position: relative;
    justify-content: center;
    font-size: clamp(14px,2cqmin,16px);
}
