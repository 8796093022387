.container{
     padding: 128px 0;
     min-height: auto;
}

.content{
     gap: 64px;
     display: flex;
     margin: 0 auto;
     padding: 0 22px;
     text-align: center;
     flex-direction: column;
     justify-content: center;
     width: min(90vw,960px);
     @media (max-width: 1000px) {
          margin: 0;
          width: 100%;
          flex-direction: column;
      }
 }
 .content > h1{
      width: 500px;
      margin: 0 auto;
      @media (max-width: 1000px) {
           margin: 0;
          width: auto;
          font-size: 24px;
      }
 }

.content_items{
     gap: 32px;
     display: flex;
     text-align: center;
     @media (max-width: 1000px) {
          gap: 62px;
          flex-direction: column;
      }
}

.content_item{
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
}

.content_item_icon{
     width: 42px;
     height: 42px;
     padding: 10px;
     background: #333;
     border-radius: 50%;
}