
.menu{
  z-index: 9;
  top: 25px;
  right: 25px;
  width: 115px;
  height: 115px;
  display: none;
  position: relative;
  object-fit: contain;
  position: absolute;
}

  @media (max-width: 1020px) {
    .menu{
      display: flex;
      animation: fade-anim 500ms cubic-bezier(0.075, 0.82, 0.165, 1) 1500ms backwards;
    }
    @keyframes fade-anim {
      from{opacity: 0;}
      to{opacity: 1;}
    }
  }
  .menu>div
  {
      top: 0;
      left: 0;
      right: 0;
      height: 2px;
      transition: 500ms;
      border-radius: 8px;
      position: absolute;
      background: #FFFFFFAA;
  }

  .menu[data-active="false"] > div:nth-child(1){
    transform: translateY(10px) rotateZ(0deg);
    animation: div1-hide-anim 500ms ease-in-out;
  }

  .menu[data-active="false"] > div:nth-child(2){
      opacity: 1;
      transform: translateY(20px);
      animation: div2-hide-anim 500ms ease-in-out;
    }

    .menu[data-active="false"] >div:nth-child(3){
    transform: translateY(30px);
    animation: div3-hide-anim 500ms ease-in-out;
    }



.menu[data-active="true"] > div:nth-child(1){
    animation: div1-active-anim 500ms ease-in-out;
    transform: translateY(20px) rotateZ(45deg);  
}
.menu[data-active="true"] > div:nth-child(2){
    opacity: 0;
    animation: div2-active-anim 500ms ease-in-out;
}
.menu[data-active="true"] >div:nth-child(3){
  animation: div3-active-anim 500ms ease-in-out;
  transform: translateY(20px) rotateZ(-45deg);  
}


  @keyframes div1-active-anim {
    0%{
      transform: translateY(10px);  
    }
    50%{
      transform: translateY(20px);  
    }
    100%{
      transform: translateY(20px) rotateZ(45deg);  
    }
  }

  @keyframes div2-active-anim {
    0%{
      opacity: 1;
      transform: translateY(20px);
    }
    100%{
      opacity: 0;
      transform: translateY(20px);
    }
  }

  @keyframes div3-active-anim {
    0%{
      transform: translateY(30px);  
    }
    50%{
      transform: translateY(20px);  
    }
    100%{
      transform: translateY(20px) rotateZ(-45deg);  
    }
  }

  /* ------ */

  @keyframes div1-hide-anim {
    0%{
      transform: translateY(20px) rotateZ(45deg);  
    }
    50%{
      transform: translateY(20px);  
    }
    100%{
      transform: translateY(10px) rotateZ(0deg);  
    }
  }

  @keyframes div2-hide-anim {
    0%{
      opacity: 0;
      transform: translateY(20px);
    }
    100%{
      opacity: 1;
      transform: translateY(20px);
    }
  }

  @keyframes div3-hide-anim {
    0%{
    transform: translateY(20px) rotateZ(-45deg);  
    }
    50%{
      transform: translateY(20px);  
    }
    100%{
      transform: translateY(30px) rotateZ(0deg);  
    }
  }