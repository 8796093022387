.container{
    inset: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    background: #000;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: 500ms ease-in-out;
}
.container[data-open="hide"]{
    opacity: 0;
    pointer-events: none;
}

.container > img {
    width: 125px;
    animation: animation 1000ms ease-in-out infinite alternate;
}

@keyframes animation {
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(0.9);
    }
}