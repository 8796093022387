.container{
     display: flex;
     padding: 128px 0;
     background: #FFF;
}

.header{
     font-size: 48px;
     margin-bottom: 96px;
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
}


.content{
     gap: 32px;
     display: flex;
     margin: 0 auto;
     padding: 0 22px;
     overflow: hidden;
     flex-direction: column;
     width: min(90vw,960px);
     @media (max-width: 800px) {
          margin: 0;
          width: 100%;
   }
}

.content_items{
     gap: 18px;
     display: grid;
     grid-template-columns: 1fr 1fr;
     @media (max-width: 800px) {
               grid-template-columns: 1fr;
        }
}

.content_header{
     display: flex;
     margin-bottom: 32px;
     flex-direction: column;
 }
 
   .content_header>h1{
     color: #000;
     font-size: min(8vmin,54px);
   }

   .content_header>p{
     margin: 0;
     color: #000;
     width: min(90vw,750px);
   }

.content_item{
     gap: 12px;
     display: flex;
     background: #000;
     border-radius: 18px;
     flex-direction: column;
     padding: 32px 32px 32px 32px;
}

.content_item > img{
     width: 54px;
     padding: 8px;
     background: #333;
     border-radius: 20px;
}

.content_item > p{
     margin: 0;
     font-size: 14px;
}

.content_item > a{
     margin-top: 8px;
     color: var(--green-color);;
}