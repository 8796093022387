:root{
  --text-color:#FFF;
  --p-line-height:24px;
  --green-color:#87c2ff;
  --background-color:#000;
  --text-color-soft:#FFFFFF88;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  overscroll-behavior: none;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito Sans", sans-serif;
}
html{
  scrollbar-gutter: stable;
  color: var(--text-color);
  background: var(--background-color);
}

*{
  box-sizing: border-box;
}

p{
  letter-spacing: 0.6px;
  color: var(--text-color-soft);
}
h1{
  font-size: 28px;
}
h1,h2,h3,h4,h5{
  margin: 0;
}

.divider{
  height: 1px;
  display: flex;
  margin: 0 auto;
  width: min(90vw,1280px);
  background-color: rgba(255,255,255,0.25);
}

a{
  color: #FFFFFF88;
  text-decoration: none;
  transition: 250ms ease-in-out;
}

a:hover{
  color: #FFFFFF;
}

.pointer{
  top: -75px;
  left: -75px;
  width: 150px;
  opacity: 0.5;
  height: 150px;
  z-index: 99;
  position: absolute;
  border-radius: 50%;
  filter: blur(100px);
  pointer-events: none;
  -webkit-filter: blur(100px);
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  background: radial-gradient(circle at center,var(--green-color) 10%, rgba(255,255,255,0.1) 80%);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


section{
  z-index: 4;
  display: flex;
  min-height: 50vh;
  flex-direction: column;
}

strong{
 color:var(--green-color)
}

.main{
  display: flex;
  flex-direction: column;
  transition: 500ms ease-in-out;
}


@media (max-width: 1020px) {
  .desktop{
      display: none !important;
  }
}